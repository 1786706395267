export const decks_config = [
    {
        "id": "Deck_1",
        "name": "DECK 1 - TANK TOP",
        "nr": 1,
        "source": "/deck1.svg",
        "lanes": {
            "Lane_1": {
                "count": 4
            },
            "Lane_2": {
                "count": 5
            },
            "Lane_3": {
                "count": 7
            },
            "Lane_4": {
                "count": 8
            },
            "Lane_5": {
                "count": 8
            },
            "Lane_6": {
                "count": 7
            },
            "Lane_7": {
                "count": 6
            },
            "Lane_8": {
                "count": 4
            }
        }
    },
    {
        "id": "Deck_3",
        "name": "DECK 3 - MAIN DECK",
        "nr": 3,
        "source": "/deck3.svg",
        "lanes": {
            "Lane_1": {
                "count": 5
            },
            "Lane_2":{
                "count": 6
            },
            "Lane_3":{
                "count": 7
            },
            "Lane_4": {
                "count": 13
            },
            "Lane_5": {
                "count": 14
            },
            "Lane_6": {
                "count": 14
            },
            "Lane_7": {
                "count": 13
            },
            "Lane_8": {
                "count": 7
            },
            "Lane_9": {
                "count": 6
            },
            "Lane_10": {
                "count": 5
            }
        }
    },
    {
        "id": "Deck_5",
        "name": "DECK 5 - UPPER DECK 1",
        "nr": 5,
        "source": "/deck5.svg",
        "lanes": {
            "Lane_1": {
                "count": 4
            },
            "Lane_2": {
                "count": 6
            },
            "Lane_3": {
                "count": 10
            },
            "Lane_4": {
                "count": 15
            },
            "Lane_5": {
                "count": 15
            },
            "Lane_6": {
                "count": 15
            },
            "Lane_7": {
                "count": 15
            },
            "Lane_8": {
                "count": 10
            },
            "Lane_9": {
                "count": 6
            },
            "Lane_10": {
                "count": 4
            }
        }
    },
    {
        "id": "Deck_7",
        "name": "DECK 7 - UPPER DECK 2",
        "nr": 7,
        "source": "/deck7.svg",
        "lanes": {
            "Lane_1_1": {
                "count": 1
            },
            "Lane_2_1": {
                "count": 5
            },
            "Lane_2_3":{
                "count": 1
            },
            "Lane_3": {
                "count": 15
            },
            "Lane_4": {
                "count": 15
            },
            "Lane_5": {
                "count": 15
            },
            "Lane_6": {
                "count": 15
            },
            "Lane_7": {
                "count": 15
            },
            "Lane_8": {
                "count": 15
            },
            "Lane_9_1":{
                "count": 3
            },
            "Lane_9_2": {
                "count": 1
            },
            "Lane_9_3": {
                "count": 5
            },
            "Lane_10_1": {
                "count": 2
            },
            "Lane_10_2": {
                "count": 4
            }
        }
    },
    {
        "id": "Deck_8",
        "name": "DECK 8 - WEATHER DECK",
        "nr": 8,
        "source": "/deck8.svg",
        "lanes": {
            "Lane_1_1": {
                "count": 4
            },
            "Lane_1_2":{
                "count": 1
            },
            "Lane_2_1": {
                "count": 4
            },
            "Lane_2_2":{
                "count": 1
            },
            "Lane_3": {
                "count": 10
            },
            "Lane_4": {
                "count": 10
            },
            "Lane_5": {
                "count": 10
            },
            "Lane_6": {
                "count": 10
            },
            "Lane_7": {
                "count": 10
            },
            "Lane_8": {
                "count": 10
            },
            "Lane_9": {
                "count": 10
            },
            "Lane_10": {
                "count": 10
            }
        }
    }
]