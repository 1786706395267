import { useState, useRef, useEffect } from 'react';
import { decks_config } from './Decks';
import SVG from 'react-inlinesvg';
import './Vessel.css';
import Deck from './Deck';

export default function Vessel_Hollandia(props) {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const svgRef = useRef(null);
    const [svgCoordiates, setSvgCoordinates] = useState({ x: 0, y: 0 });
    const [viewBox, setViewBox] = useState({ x: 0, y: 0, w: width, h: height });
    const [scale, setScale] = useState(1);
    const [isPanning, setIsPanning] = useState(false);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });

    const centerX = window.innerWidth / 2.0;
    const centerY = window.innerHeight / 2.0;

    function centerView() {
        const { width, height } = svgRef.current.getBBox();
        const w = width / 2.0;
        const h = height / 2.0;

        setSvgCoordinates({ x: centerX - w, y: centerY - h });
    };


    function handleLoaded() {
        centerView();

    }

    useEffect(() => {
        if (svgRef.current) {
            const initialScale = 2;
            const { x, y } = svgCoordiates;
            const scaledX = initialScale * (x - centerX) + centerX;
            const scaledY = initialScale * (y - centerY) + centerY;
            svgRef.current.setAttribute('transform', `matrix(${initialScale}, 0, 0, ${initialScale}, ${scaledX}, ${scaledY})`);
        }

    }, [svgCoordiates])


    function onMouseWheelHandler(e) {
        let w = viewBox.w;
        let h = viewBox.h;
        let mx = e.nativeEvent.offsetX;
        let my = e.nativeEvent.offsetY;
        let dw = w * Math.sign(e.deltaY * -1) * 0.05;
        let dh = h * Math.sign(e.deltaY * -1) * 0.05;
        let dx = dw * mx / width;
        let dy = dh * my / height;
        let newViewBox = { x: viewBox.x + dx, y: viewBox.y + dy, w: viewBox.w - dw, h: viewBox.h - dh }
        setViewBox(newViewBox);
        setScale(width / newViewBox.w);
    }

    function onMouseDownHandler(e) {
        setIsPanning(true);
        setStartPoint({ x: e.clientX, y: e.clientY });
    }

    function onMouseMoveHandler(e) {
        if (isPanning) {
            const endPoint = { x: e.clientX, y: e.clientY };
            const dx = (startPoint.x - endPoint.x) / scale;
            const dy = (startPoint.y - endPoint.y) / scale;
            setViewBox((prev) => ({
                x: prev.x + dx,
                y: prev.y + dy,
                w: prev.w,
                h: prev.h,
            }));
            //If start point is not updated every mouse move then the panning becomes weird, but in vanilla javascript only the start point on mouse down is enough?
            setStartPoint(endPoint);
        }
    }

    function onMouseUpHandler(e) {
        if (isPanning) {
            setIsPanning(false);
        }
    }

    function onMouseLeaveHandler(e) {
        setIsPanning(false);
    }

    const lane_1 = {
        width: 38.67,
        height: 7.48
    }

    const legendItems = [
        { color: "orange", label: "Unknown", "type": "parking_slot" },
        { color: "red", label: "Occupied", "type": "parking_slot" },
        { color: "green", label: "Free", "type": "parking_slot" },
        { color: "blue", label: "Allocated", "type": "parking_slot" },
        { color: "black", label: "Unofficial", "type": "parking_slot_unofficial" },
        { color: "#02df02", label: "Trip load", "type": "trip" },
        { color: "#8c8cf7", label: "Trip unload", "type": "trip" },
        { color: "teal", label: "Trip yard shift", "type": "trip" }
    ];

    const legendDropdown =
        <div style={{
            padding: 10,
            border: '1px solid rgba(255, 255, 255, 0.4)',
            borderRadius: 8,
            background: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
        }}>
            <div style={{ marginBottom: 8, fontWeight: 'bold', color: '#333' }}>Legend</div>
            {legendItems.map((item, index) => {
                if (item.type == "parking_slot_unofficial") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 22,
                                marginRight: 8,
                                border: '3px dotted #000'
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }
                if (item.type == "parking_slot") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 22,
                                background: item.color,
                                marginRight: 8,
                                border: '1px solid #000'
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }
                if (item.type == "trip") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 6,
                                background: item.color,
                                marginRight: 8
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }

            })}
        </div>


    return (
        <div className="vessel-root">
            <div id="filtersbox" style={{ zIndex: 998, position: "absolute", top: 4, right: 4, padding: 4 }}>
                <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                    <div style={{ width: 142 }}>
                        {legendDropdown}
                    </div>
                </div>
            </div>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
                onWheel={onMouseWheelHandler}
                onMouseDown={onMouseDownHandler}
                onMouseMove={onMouseMoveHandler}
                onMouseUp={onMouseUpHandler}
                onMouseLeave={onMouseLeaveHandler}

            >

                <g ref={svgRef}>
                    {decks_config.map((value, i) => {
                        return <g key={value.id}><Deck yCoord={i * 100} source={value.source} handleLoaded={handleLoaded} config={value} svgRef={svgRef} /></g>
                    })}
                </g>


            </svg>



        </div>
    )
}