import React, {useState, useEffect, useLayoutEffect} from "react";
import axios from "axios";

import { Input, Form, Button, Typography, Col, Row, Spin, Alert, Space } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import App from "./App";
import { authHeader } from "./Helpers";


function useWindowSize() {
    const [height, setHeight] = useState([window.innerHeight - 1, window.outerHeight - 1]);
    useLayoutEffect(() => {
        function updateSize() {
            setHeight([window.innerHeight - 1, window.outerHeight - 1]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return height;
}


function Login(){

    const [loggingIn, setLoggingIn] = useState(false);
    const [error, setError] = useState('');

    const [userAuthenticated, setUserAuthenticated] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const [intFrameHeight, intOuterFrameHeight] = useWindowSize();

    useEffect(() => {
        (async () => {
            // Set default authorization header if a token already exists
            axios.defaults.headers.common['Authorization'] = authHeader()
            await getUserInfo()
            setLoading(false)
        })()

    }, [])

    async function getUserInfo(){
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/users/me')
            .then(response => {
                if (response.status === 200 && response.data.username) {
                    setUserAuthenticated(true)
                } else {
                    console.error("No user profile found...", error);
                }
            })
            .catch(error => {
                console.error("No user profile found...", error);
            });
    }

    const login = (username, password) => {
        setLoggingIn(true);

        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        fetch(window.vtrack_config.REACT_APP_API_URL + '/token', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.access_token != null){
                    localStorage.setItem('token', data.access_token);
                    axios.defaults.headers.common['Authorization'] = authHeader()
                    setUserAuthenticated(true)
                } else {
                    setError("Authorization failed...")
                    setLoggingIn(false);
                }
            })
            .catch(error => {
                console.error(error); 
                setLoggingIn(false)
                setError(error)
            })
    }

    const handleSubmitLogin = values => {
        login(values.username, values.password)
    };

    const loginForm =
        <Form onFinish={handleSubmitLogin}>
            <Form.Item name="username" style={{marginBottom: 5}} rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input
                    disabled={loggingIn}
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Username"
                />
            </Form.Item>
            <Form.Item name="password" style={{marginBottom: 0}} rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input
                    disabled={loggingIn}
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item style={{marginTop: 5, marginBottom: 0}}>
                <Button loading={loggingIn} type="primary" htmlType="submit" style={{width: "100%", background: '#7532a8', borderColor: '#7532a8'}}>
                    {loggingIn ? "Logging in..." : "Log in"}
                </Button>
            </Form.Item>
        </Form>

    const loginComponent = 
        <React.Fragment>
            <Row style={{paddingTop: '25vh'}}>
                <Col span={9}></Col>
                <Col span={6} style={{minWidth: 300}}>
                    <span style={{textAlign: "center", display: "block"}}>
                        <Row justify="center">
                            <Col>
                                <img src='./images/AngePurpleSmooth.png' style={{maxWidth:50, maxHeight:50, marginBottom: 5}} />
                            </Col>
                            <Col style={{marginLeft: 8}}>
                                <Typography.Title level={1} style={{color: "#54146b", marginTop: 0, marginBottom: 10}}>
                                    VizTrack Cargo Operations
                                </Typography.Title>
                            </Col>
                        </Row>
                    </span>
                    {loginForm}
                    {error &&
                        <Space
                            direction="vertical"
                            style={{ width: '100%', marginTop: 4 }}
                        >
                            <Alert
                                message={error}
                                type="error"
                                showIcon
                                closable
                                onClose={() => setError('')}
                            />
                        </Space>
                    }
                </Col>
                <Col span={9}></Col>
            </Row>
        </React.Fragment>

    const placeholder =
        <span style={{textAlign: "center", display: "block", paddingTop: 0.3*intFrameHeight}}>
            <Spin spinning={true} size="large" tip="Logging in to VTrack UI..." style={{marginTop: 0}}>
                <img src='./images/AngePurpleSmooth.png' style={{minWidth:200, minHeight:200}} />
            </Spin>
        </span>

    return (
        <React.Fragment>
            { isLoading
                ? placeholder
                : userAuthenticated
                    ? <App />
                    : loginComponent
            }
        </React.Fragment>
    );
}

export default Login;
