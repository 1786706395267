import React, {useRef, useState} from "react";
const { DateTime } = require("luxon");

import { Typography, Tag, Button, Input, Space, Table } from 'antd';
import {SearchOutlined} from '@ant-design/icons'; 
const { Text } = Typography;

import ProTable from '@ant-design/pro-table';

import {singleTripLink} from "../Helpers";


export default function TrailerTable(props){

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                close
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        }
      });
    
    const columns = [
        { title: 'Trailer #', ...getColumnSearchProps('trailer_number'), width: 100, dataIndex: 'trailer_number'},

        { title: 'Created/Updated', dataIndex: 'created_updated', width: 220, sorter: (a, b) => DateTime.fromISO(a.created, {zone: "utc"}) - DateTime.fromISO(b.created, {zone: "utc"}), sortDirections: ['ascend', 'descend'], render: (_val, record) =>
            <>
                <Text strong>Created: </Text><Text>{!!record.created ? DateTime.fromISO(record.created, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.created}</Text><br/>
                <Text strong>Updated: </Text><Text>{!!record.updated ? DateTime.fromISO(record.updated, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.updated}</Text>
            </>
        },
        { title: 'Message ID', width: 100, dataIndex: 'message_id'},
        { title: 'Status', width: 100, render: (_val, record) => 
            <>
                {(record.yard_id === "" || record.yard_id == null) 
                    ? <Tag color="#666">ON VESSEL</Tag>
                    : <Tag color="#4287f5">ON YARD</Tag>
                }
            </>
        },
        { title: 'Deck', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>ID: </Text><Text>{record.deck_id}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.deck_time ? DateTime.fromISO(record.deck_time, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.deck_time}</Text>
                </React.Fragment>
        },
        { title: 'Trailer Position', width: 200,sorter: (a, b) => {
                const timeA = a.position_time ? DateTime.fromISO(a.position_time, {zone: "utc"}) : null;
                const timeB = b.position_time ? DateTime.fromISO(b.position_time, {zone: "utc"}) : null;

                if (timeA && timeB) {
                    return timeA - timeB;
                }
                if (timeA) {
                    return 1; // place non-null values after null values
                }
                if (timeB) {
                    return -1; // place null values before non-null values
                }
                return 0; // if both are null, consider them equal
            }, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Latitude: </Text><Text>{record.position_latitude}</Text><br/>
                    <Text strong>Longitude: </Text><Text>{record.position_longitude}</Text><br/>
                        {record.yard_id && (
                            <>
                                <Text strong>Yard ID:</Text> {record.yard_id}<br />
                            </>
                        )}
                    <Text strong>Time: </Text><Text>{!!record.position_time ? DateTime.fromISO(record.position_time, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.position_time}</Text>
                </React.Fragment>
        },
        { title: 'Tractor', width: 200, sorter: (a, b) => DateTime.fromISO(a.truck_time, {zone: "utc"}) - DateTime.fromISO(b.truck_time, {zone: "utc"}), sortDirections: ['ascend', 'descend'], render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{record.truck_tag}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.truck_time ? DateTime.fromISO(record.truck_time, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.truck_time}</Text>
                </React.Fragment>
        },
        { title: 'Trip', width: 200, sorter: (a, b) => DateTime.fromISO(a.trip_time, {zone: "utc"}) - DateTime.fromISO(b.trip_time, {zone: "utc"}), render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Active: </Text><Text>{record.trip_active ? "True" : "False"}</Text><br/>
                    <Text strong>ID: </Text><Text>{singleTripLink(record.trip_id)}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.trip_time ? DateTime.fromISO(record.trip_time, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.trip_time}</Text>
                </React.Fragment>
        },
    ];


    return <ProTable
        columns={columns}
        loading={props.loading}
        pagination={{ pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
        bordered={true}
        rowKey="trailer_number"
        dataSource={props.data}
        scroll={{ y: props.scroll }}
        search={false}
        options={{'reload': () => props.fetchData()}}
    />;
}