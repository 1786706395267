import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Image, Pagination, Row, Col, Spin, Typography } from "antd";
const {Text} = Typography;
import axios from "axios";
import { formatDate } from "../Helpers";
import { DateTime } from "luxon";

export default function TripFrames() {
    const params = useParams();
    const [tripId, setTripId] = useState(null);
    const [frameImages, setFrameImages] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(18);
    const [totalImages, setTotalImages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(null); // Track current image index for preview


    useEffect(() => {
        if (params?.tripId) {
            setTripId(params.tripId);
        }
    }, [params]);

    useEffect(() => {
        if (tripId) {
            fetchTripFrames(tripId, page, pageSize);
        }
    }, [tripId, page, pageSize]);

    async function fetchTripFrames(trip, page, pageSize) {
        setLoading(true);
        try {
            const response = await axios.get(
                `${window.vtrack_config.REACT_APP_API_URL}/nextcloud-gallery/${trip}`,
                { params: { page, pageSize } }
            );
            if (response.status === 200) {
                let tempData = response.data.images;
                tempData.forEach(image => {
                    const parts = image.name.split("_");
                    //first replace all dots with columns, then replace last column with dot
                    const formattedDate = DateTime.fromISO(parts[1].replace(/\./g, ":").replace(/:([^:]*)$/, '.$1'), {zone: "utc"});

                    image.date = formattedDate;
                });

                setFrameImages(response.data.images);
                setTotalImages(response.data.total || 0);  // If your backend provides total count
            }
        } catch (error) {
            console.error("Error fetching trip frames:", error);
        } finally {
            setLoading(false);
        }
    }

    function formatImageDate(formattedDate) {
        return formattedDate.toFormat("yyyy/MM/dd hh:mm:ss.SSS");
    }

    const handlePageChange = (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
    };

    // Function to handle keyboard navigation in preview mode
    const handlePreviewKeyDown = (event) => {
        if (currentImageIndex === null) return; // Only allow navigation if in preview mode

        if (event.key === "ArrowRight") {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % frameImages.length);
        } else if (event.key === "ArrowLeft") {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex - 1 + frameImages.length) % frameImages.length
            );
        }
    };

    // Effect to add/remove event listener for keyboard navigation
    useEffect(() => {
        if (currentImageIndex !== null) {
            window.addEventListener("keydown", handlePreviewKeyDown);
        } else {
            window.removeEventListener("keydown", handlePreviewKeyDown);
        }

        return () => window.removeEventListener("keydown", handlePreviewKeyDown);
    }, [currentImageIndex]);

    return (
        <>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "83vh", width: "100%" }}>
                    <Spin size="large" />
                </div>
            ) : (
                <div style={{ padding: 16}}>
                    <Pagination
                        style={{ textAlign: "center", marginBottom: "20px" }}
                        current={page}
                        pageSize={pageSize}
                        total={totalImages}
                        showSizeChanger
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                    />
                    <Row gutter={[16, 16]} style={{maxHeight: '83vh', overflowY: 'auto'}}>
                        {frameImages.map((image, index) => (
                            <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Image
                                    src={`data:image/jpeg;base64,${image.image_data}`}
                                    alt={image.name}
                                    width="100%"
                                    height="auto"
                                    preview={{
                                        visible: currentImageIndex === index,
                                        onVisibleChange: (visible) => {
                                            setCurrentImageIndex(visible ? index : null);
                                        }
                                    }}
                                />
                            <Text strong style={{position: "absolute", left: 8, left: 16, color: "#1677ff", fontSize: "2.5em", cursor: "pointer", pointerEvents: "none", WebkitTextStroke: "0.5px #000000"}} copyable={false}>{index+1 + ((page-1) * pageSize)}</Text>
                            <Text strong>{formatImageDate(image.date)}</Text>
                            </Col>
                        ))}
                    </Row>
                    <Pagination
                        style={{ textAlign: "center", marginTop: "20px" }}
                        current={page}
                        pageSize={pageSize}
                        total={totalImages}
                        showSizeChanger
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                    />
                </div>
            )}
        </>
    );
}
