// index.js
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";

import App from "./App";
import Login from "./Login";

ReactDOM.render(
    <HashRouter>
        {window.vtrack_config.USE_API_LOGIN ? <Login /> : <App />}
    </HashRouter>,
document.getElementById("root")
);
