import "./styles.css";
import ProTable from '@ant-design/pro-table';
import { Checkbox, Spin, Typography, message } from 'antd';
import { useEffect, useState, PureComponent } from 'react';
import axios from 'axios';
import React from "react";
const { Text } = Typography;
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { tripTypeColor } from '../Helpers';

import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import Title from 'antd/es/typography/Title';

export default function Terminal(props) {
    const [data, setData] = useState([]);
    const [showOriginal, toggleOriginalData] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);

    const options = {

        data: data,
        series: [
            { type: 'bar', xKey: 'trip_date', yKey: 'unloadings', yName: 'Unloadings', stacked: true },
            { type: 'bar', xKey: 'trip_date', yKey: 'loadings', yName: 'Loadings', stacked: true },
            { type: 'bar', xKey: 'trip_date', yKey: 'yard_shifts', yName: 'Yard shifts', stacked: true },
            { type: 'bar', xKey: 'trip_date', yKey: 'empty_trips', yName: 'Empty trips', stacked: true }
        ],
    }




    useEffect(async () => {
        setChartLoading(true);
        props.fetchData();

        // Fetch original trip statistics
        let originalDataResponse = [];
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/metrics/trips_per_day")
            .then(response => {
                if (response.status === 200) {
                    originalDataResponse = response.data.reverse();

                } else {
                    console.log(response);
                    message.error("Could not load original trip statistics");

                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load original trip statistics");

            });

        // Fetch merged trip statistics
        let mergedDataResponse = [];
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/metrics/merged_trips_per_day")
            .then(response => {
                if (response.status === 200) {
                    mergedDataResponse = response.data;
                } else {
                    console.log(response);
                    message.error("Could not load merged trip statistics");
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load merged trip statistics");
            });

        // Combine original and merged data
        const combinedResult = originalDataResponse.map(originalItem => {
            // Find the corresponding original data for the same trip_date
            const mergedItem = mergedDataResponse.find(
                merged => merged.trip_date === originalItem.trip_date
            );

            if (mergedItem) {
                return {
                    ...originalItem,
                    merged_trip_count: mergedItem.trip_count,
                    merged_unloadings: mergedItem.unloadings,
                    merged_loadings: mergedItem.loadings,
                    merged_yard_shifts: mergedItem.yard_shifts,
                    merged_empty_trips: mergedItem.empty_trips
                };
            }

            // If there's no matching original data, return merged data as-is
            return originalItem;
        });

        setChartLoading(false);
        // Set combinedData state
        setData(combinedResult);
    }, []);


    // const axes = [
    //     {
    //         primary: true,
    //         type: 'time',
    //         position: 'bottom',
    //         show: true,  // Ensure x-axis is shown
    //         tickCount: data[0]?.data.length || 30,  // Adjust number of ticks to match number of data points (bars)
    //         format: (date) => `${date.toLocaleDateString('en-UK', { month: 'short', day: 'numeric' })} (${date.toLocaleDateString('en-UK', { weekday: 'short' })})`
    //     },
    //     { type: 'linear', position: 'left' }
    // ];

    //console.log(data[0]?.data.length || 30);

    const columns = [
        {
            title: '',
            dataIndex: 'total',
            key: 'total',
            width: 150,
            render: (text, record) =>
                <>
                    <Text>{text.charAt(0).toUpperCase() + text.slice(1)}</Text>
                </>

        },
        {
            title: 'Total',
            dataIndex: 'trips',
            key: 'trips',
        },
        {
            title: "24 hours",
            dataIndex: "last_24_hours",
            key: "last_24_hours",
        },
        {
            title: "Today",
            dataIndex: "today",
            key: "today"
        },
        {
            title: "Yesterday",
            dataIndex: "yesterday",
            key: "yesterday"
        },
        {
            title: "Last 7 days",
            dataIndex: "last_7_days",
            key: "last_7_days"
        }
    ]


    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const date = new Date(payload.value);
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`${date.toLocaleDateString("en-UK", {
                            month: "short",
                            day: "numeric",
                        })} (${date.toLocaleDateString("en-UK", { weekday: "short" })})`}
                    </text>
                </g>
            );
        }
    }

    function CustomTooltip(props) {
        if (props.active && props.payload[0] != null) {
            let newPayload = [
                ...props.payload,
                {
                    name: "Trips count",
                    value: props.payload[0].payload.trip_count
                },
                {
                    name: "Merged trips count",
                    value: props.payload[0].payload.merged_trip_count
                },
            ];

            if (showOriginal) {
                newPayload = newPayload.filter(item => !item.dataKey?.startsWith("merged"));
            }

            return <DefaultTooltipContent {...props} payload={newPayload} />
        } else {
            return <DefaultTooltipContent {...props} />;
        }
    }

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>
            <ProTable
                columns={columns}
                dataSource={props.data}
                loading={props.loading}
                search={false}
            />

            <Title level={2}>Trips per Day</Title>

            <Checkbox onChange={e => { toggleOriginalData(e.target.checked) }}>Show original data</Checkbox>

            <p style={{ minHeight: '40px' }}>
                {showOriginal ? (
                    <>
                        Left bar - original data <br />
                        Right bar - merged data
                    </>
                ) : (
                    "Displaying merged data"
                )}
            </p>

            <div style={{ height: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {chartLoading ? (
                    <Spin tip="Loading..." /> // Show spinner when loading
                ) : (
                    <ResponsiveContainer width="100%" height="100%">

                        <BarChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 128,
                            }}
                            padding={{
                                bottom: 160,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="trip_date"
                                height={60}
                                tick={<CustomizedAxisTick />}
                                interval={0}
                            />
                            <YAxis />
                            <Tooltip labelFormatter={(label) => {
                                const date = new Date(label);
                                return date.toLocaleDateString("en-UK", {
                                    weekday: "long",  // Full weekday name
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                });
                            }}
                            formatter={(value, name, props) => {
                                if (props.payload && showOriginal && !name.startsWith("Merged")) {
                                    const merged_name = 'merged_' + name.toLowerCase().replace(/ /g, '_');
                                    const merged_value = props.payload[merged_name];
                                    return [`${value} (${merged_value} after merging)`, name];
                                } else {
                                    return [value, name];
                                }
                            }}
                                content={<CustomTooltip />} />
                            <Legend wrapperStyle={{ marginBottom: "-48px" }} />
                            {showOriginal && (
                                <React.Fragment>
                                    <Bar dataKey="unloadings" name="Unloadings" stackId="a" fill={tripTypeColor[1]} />
                                    <Bar dataKey="loadings" name="Loadings" stackId="a" fill={tripTypeColor[2]} />
                                    <Bar dataKey="yard_shifts" name="Yard Shifts" stackId="a" fill={tripTypeColor[3]} />
                                    <Bar dataKey="empty_trips" name="Empty Trips" stackId="a" fill={tripTypeColor[0]} />
                                </React.Fragment>
                            )}
                            {/* Merged Trip Data (Side-by-Side) */}
                            <defs>
                                {/* Pattern for Unloadings */}
                                <pattern id="unloadingsHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                    <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                        style={{ stroke: tripTypeColor[1], strokeWidth: 2 }} />
                                </pattern>

                                {/* Pattern for Loadings */}
                                <pattern id="loadingsHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                    <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                        style={{ stroke: tripTypeColor[2], strokeWidth: 2 }} />
                                </pattern>

                                {/* Pattern for Yard Shifts */}
                                <pattern id="yardShiftsHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                    <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                        style={{ stroke: tripTypeColor[3], strokeWidth: 2 }} />
                                </pattern>

                                {/* Pattern for Empty Trips */}
                                <pattern id="emptyTripsHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                    <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                        style={{ stroke: tripTypeColor[0], strokeWidth: 2 }} />
                                </pattern>
                            </defs>

                            {/* Applying patterns to the bars */}
                            <Bar dataKey="merged_unloadings" stackId="b" name={showOriginal ? "Merged Unloadings" : "Unloadings"} fill={showOriginal ? "url(#unloadingsHatch)" : tripTypeColor[1]} legendType={!showOriginal ? 'rect' : 'none'} />
                            <Bar dataKey="merged_loadings" stackId="b" name={showOriginal ? "Merged Loadings" : "Loadings"} fill={showOriginal ? "url(#loadingsHatch)" : tripTypeColor[2]} legendType={!showOriginal ? 'rect' : 'none'} />
                            <Bar dataKey="merged_yard_shifts" stackId="b" name={showOriginal ? "Merged Yard Shifts" : "Yard Shifts"} fill={showOriginal ? "url(#yardShiftsHatch)" : tripTypeColor[3]} legendType={!showOriginal ? 'rect' : 'none'} />
                            <Bar dataKey="merged_empty_trips" stackId="b" name={showOriginal ? "Merged Empty Trips" : "Empty Trips"} fill={showOriginal ? "url(#emptyTripsHatch)" : tripTypeColor[0]} legendType={!showOriginal ? 'rect' : 'none'} />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>

        </div>
    )
}