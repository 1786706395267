import React, { useEffect } from "react";
const { DateTime } = require("luxon");
import { Link } from 'react-router-dom';

import { Typography, Tag, Table, Button } from 'antd';
const { Text } = Typography;
import { getTripDuration, singleTripURL } from "../Helpers";


export default function TripMergeTable(props) {


    const columns = [
        {
            title: 'Selected Trip', width: 180, render: (_val, record) =>
                <>
                    {props.tripSelectionBox}<br />
                    <div>
                        <a href={singleTripURL(record.prev_trip)}>
                            <Button type="primary" disabled={true}>
                                Previous Trip
                            </Button>
                        </a>
                        <a href={singleTripURL(record.next_trip)}>
                            <Button type="primary" disabled={true} style={{ marginLeft: 8 }}>
                                Next Trip
                            </Button>
                        </a>
                    </div>
                </>
        },
        {
            title: 'Trip Info', width: 180, sortDirections: ['ascend', 'descend'], render: (_val, record) =>
                <>
                    <Text strong>Trip ID: </Text><Text>{record.trip_id}</Text><br />
                    <Text strong>Tractor ID: </Text><Text>{record.tag}</Text><br />
                    <Text strong>Trip duration: </Text> {getTripDuration(record)}
                </>
        },
        {
            title: 'Created/Updated', width: 220, render: (_val, record) =>
                <>
                    <Text strong>Created: </Text><Text>{!!record.created ? DateTime.fromISO(record.created, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.created}</Text><br />
                    <Text strong>Updated: </Text><Text>{!!record.updated ? DateTime.fromISO(record.updated, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.updated}</Text>
                </>
        },
        {
            title: 'Status', width: 110, render: (_val, record) => {
                let trip_type_name = record.trip_type_rel ? record.trip_type_rel.name : null
                switch (record.trip_type) {
                    case 1:
                        return <Tag color="#509423">{trip_type_name ? trip_type_name : "UNLOAD"}</Tag>;
                    case 2:
                        return <Tag color="#e6833c">{trip_type_name ? trip_type_name : "LOAD"}</Tag>;
                    case 3:
                        return <Tag color="#666">{trip_type_name ? trip_type_name : "YARD SHIFT"}</Tag>;
                    case 4:
                        return <Tag color="#aaa">{trip_type_name ? trip_type_name : "VESSEL PICKUP"}</Tag>;
                    case 5:
                        return <Tag color="#aaa">{trip_type_name ? trip_type_name : "YARD PICKUP"}</Tag>;
                }
            }
        },
        {
            title: 'Pickup', width: 200, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Deck ID: </Text><Text>{record.pickup_deck_id}</Text><br />
                    <Text strong>Latitude: </Text><Text>{record.pickup_latitude}</Text><br />
                    <Text strong>Longitude: </Text><Text>{record.pickup_longitude}</Text><br />
                    <Text strong>Yard ID: </Text><Text>{record.pickup_yard_id}</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.pickup_time ? DateTime.fromISO(record.pickup_time, {zone: "utc"}).format('dd/MM/yyyy hh:mm:ss') : record.pickup_time}</Text>
                </React.Fragment>
        },
        {
            title: 'Discharge', width: 200, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Deck ID: </Text><Text>{record.discharge_deck_id}</Text><br />
                    <Text strong>Latitude: </Text><Text>{record.discharge_latitude}</Text><br />
                    <Text strong>Longitude: </Text><Text>{record.discharge_longitude}</Text><br />
                    <Text strong>Yard ID: </Text><Text>{record.discharge_yard_id}</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.discharge_time ? DateTime.fromISO(record.discharge_time, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.discharge_time}</Text>
                </React.Fragment>
        },
        {
            title: 'Trailer', width: 200, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{record.trailer_number}</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? DateTime.utc(record.trailer_number_time, {zone: "utc"}).toFormat('dd/MM/yyyy H:mm:ss') : record.trailer_number_time}</Text>
                </React.Fragment>
        },
    ];

    return (
        <Table
            columns={columns}
            size="small"
            loading={props.loading}
            pagination={false}
            bordered={true}
            rowKey="trip_id"
            dataSource={props.data}
            style={{ overflowX: 'scroll' }}

        />
    )
}
