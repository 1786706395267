import "./styles.css";
import { Typography, message, DatePicker, Button, Table } from 'antd';
import { useEffect, useState, PureComponent } from 'react';
import axios from 'axios';
import React from "react";
const { Text, Title } = Typography;
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { capitalizeFirstLetter, tripTypeColor } from '../Helpers';

import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';

export default function UnitNumber() {

    const [data, setData] = useState([]);
    const [totalCountData, setTotalCountData] = useState([]);
    const [fetchedStartDate, setFetchedStartDate] = useState("");
    const [fetchedEndDate, setFetchedEndDate] = useState("");
    const [verificationDate, setDate] = useState(null);  // For date selection
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        // Fetch original trip statistics
        fetchData();

    }, []);

    const fetchData = async (params = {}) => {
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/metrics/verifications_per_day", { params })
            .then(response => {
                if (response.status === 200) {
                    const responseData = response.data.data.reverse();
                    let tempTotalCount = [];
                    const tempTotal = response.data.total;
    
                    for (const [key, value] of Object.entries(tempTotal)) {
                        let percentage = 100;
    
                        if (key.toLowerCase() === "unverified") {
                            if (tempTotal.trips > 0) percentage = (value * 100) / tempTotal.trips;
                        } else if (key.toLowerCase() !== "trips") {
                            const total = tempTotal.modified + tempTotal.invalid + tempTotal.verified;
                            percentage = total > 0 ? (value * 100) / total : 0;
                        }
    
                        if (key.toLowerCase() === "modified") {
                            // Nest the character difference categories under "modified"
                            tempTotalCount.push({
                                total: "Modified",
                                key: "modified",
                                number: value,
                                percent: percentage.toFixed(2),
                                children: [
                                    {
                                        total: "1 Character Difference",
                                        number: tempTotal["1"],
                                        percent: (tempTotal["1"] * 100 / value).toFixed(2),
                                    },
                                    {
                                        total: "2 Characters Difference",
                                        number: tempTotal["2"],
                                        percent: (tempTotal["2"] * 100 / value).toFixed(2),
                                    },
                                    {
                                        total: ">2 Characters Difference",
                                        number: tempTotal[">2"],
                                        percent: (tempTotal[">2"] * 100 / value).toFixed(2),
                                    },
                                ],
                            });
                        } else if (!["1", "2", ">2"].includes(key)) {
                            // Only push other main categories, excluding character difference subcategories
                            let total = key;
                            if(key == "verified") total = "correct";
                            tempTotalCount.push({
                                total: capitalizeFirstLetter(total),
                                key: key.toLowerCase(),
                                number: value,
                                percent: percentage.toFixed(2),
                            });
                        }
                    }
    
                    setTotalCountData(tempTotalCount);
                    setFetchedStartDate(responseData[0].trip_date);
                    setFetchedEndDate(responseData[responseData.length - 1].trip_date);
                    setData(responseData);
                } else {
                    console.log(response);
                    message.error("Could not load original trip statistics");
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load original trip statistics");
            });
    };

    const fetchDataMonth = async () => {
        if (!verificationDate) {
            message.error("Please select a date.");
            return;
        }
        const month = verificationDate.get("month") + 1;
        const year = verificationDate.get("year");
        const params = {
            year: year,
            month: month
        }

        setLoading(true);

        fetchData(params);

        setLoading(false);
    };


    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const date = new Date(payload.value);
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`${date.toLocaleDateString("en-UK", {
                            month: "short",
                            day: "numeric",
                        })} (${date.toLocaleDateString("en-UK", { weekday: "short" })})`}
                    </text>
                </g>
            );
        }
    }

    function CustomTooltip(props) {
        if (props.active && props.payload && props.payload.length) {
            //Add trips count that
            const newPayload = [
                ...props.payload,
                {
                    name: "Total",
                    value: props.payload[0].payload.trip_count,
                }
            ];

            //return <DefaultTooltipContent {...props} payload={newPayload} />
            return (
                <div className="custom-tooltip">
                    <span className="tooltip-label">{`Date: ${props.label}`}</span>
                    {newPayload.map((entry, index) => (
                        <div className="tooltip-entry" key={`tooltip-entry-${index}`} style={{ color: entry.fill }}>
                            <strong>{entry.name}:</strong> {entry.value}
                            {newPayload[index].payload ? 
                                entry.name.toLowerCase() == "unverified" ? (
                                ` (${newPayload[index].payload.trip_count > 0 
                                    ? ((entry.value / newPayload[index].payload.trip_count) * 100).toFixed(2) 
                                    : 0}%)`
                            ) : (
                                ` (${(newPayload[index].payload.verified + newPayload[index].payload.modified + newPayload[index].payload.invalid) > 0 
                                    ? ((entry.value / (newPayload[index].payload.verified + newPayload[index].payload.modified + newPayload[index].payload.invalid) * 100).toFixed(2)) 
                                    : 0}%)`
                            )
                            : ""}
                            {entry.dataKey == "modified" && (
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', padding: '4px 0' }}>
                                    {Object.keys(newPayload[0].payload.levenshtein_distance).map((key, idx) => {
                                        if (key === "0") return null; // Skip rendering if key is '0'
                                        return (
                                            <React.Fragment key={`${index}-${key}-${idx}`}>
                                                <strong style={{ marginLeft: '16px', marginRight: '8px', textAlign: 'right' }} >{key} char:</strong>
                                                <span>{newPayload[0].payload.levenshtein_distance[key]}</span>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <DefaultTooltipContent {...props} />;
        }
    }

    const columns = [
        {
            title: "Total",
            key: "total",
            dataIndex: "total"
        },
        {
            title: "Number",
            key: "number",
            dataIndex: "number"
        },
        {
            title: "Percentage %",
            key: "percent",
            dataIndex: "percent"
        }
    ]

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>

            {/* Date selection */}
            <div style={{ marginTop: 20 }}>
                <Text>Select Date:</Text>
                <DatePicker
                    style={{ marginLeft: 10 }}
                    picker="month"
                    onChange={(date, dateString) => setDate(date)}
                    placeholder="Select month"
                />
            </div>

            {/* Fetch data button */}
            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={fetchDataMonth} loading={loading}>
                    Fetch Data
                </Button>
            </div>

            <Title level={2}>Verifications per day </Title>



            <Text strong>Range: </Text><span>{fetchedStartDate} - {fetchedEndDate}</span>
            <div style={{ height: '600px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={700}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 128,
                        }}
                        padding={{
                            bottom: 160,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="trip_date"
                            height={60}
                            tick={<CustomizedAxisTick />}
                            interval={0}
                        />
                        <YAxis />
                        <Tooltip labelFormatter={(label) => {
                            const date = new Date(label);
                            return date.toLocaleDateString("en-UK", {
                                weekday: "long",  // Full weekday name
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            });
                        }}
                            formatter={(value, name, props) => {

                                if (props.payload) {
                                    const totalTrips = props.payload.trip_count;  // Use the trip_count from payload
                                    const percentage = ((value / totalTrips) * 100).toFixed(2);  // Calculate percentage
                                    return [`${value} (${percentage}%)`, name];
                                } else {
                                    return [value, name];  // Return the formatted value with percentage
                                }
                            }}
                            content={<CustomTooltip />}
                        />
                        <Legend wrapperStyle={{ marginBottom: "-48px" }} />
                        <Bar dataKey="verified" name="Correct" stackId="a" fill="green" />
                        <Bar dataKey="modified" name="Modified" stackId="a" fill="blue" />
                        <Bar dataKey="invalid" name="Invalid" stackId="a" fill="orange" />
                        <Bar dataKey="unverified" name="Unverified" stackId="a" fill="red" />
                    </BarChart>
                </ResponsiveContainer>
            </div>

            <Table
                columns={columns}
                dataSource={totalCountData} 
                expandable={{
                    expandedRowKeys: ["modified"]
                }}/>
        </div>
    )
}