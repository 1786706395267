import React, { useEffect, useState, PureComponent } from 'react';
import { DatePicker, Select, Button, message, Typography } from 'antd';
import ProTable from '@ant-design/pro-table';
import axios from 'axios';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { tripTypeColor } from '../Helpers';

const { Text, Title } = Typography;
const { Option } = Select;

export default function Truck(props) {
    const [data, setData] = useState([]);

    const [truckTags, setTruckTags] = useState([]);  // For truck selection
    const [truckDate, setTruckDate] = useState(null);  // For date selection
    const [loading, setLoading] = useState(false);
    const [trucksMap, setTrucksMap] = useState({});

    useEffect(() => {
        if (props.trucks && props.trucks.length > 0) {
            let mapTemp = {};
            props.trucks.forEach(truck => {
                mapTemp[truck.tag] = truck.name;
            });
            setTrucksMap(mapTemp);
        }
    }, [props.trucks]);

    // Chart series and axes configuration
    const series = { type: 'bar' };
    const axes = [
        {
            primary: true,
            type: 'time',
            position: 'bottom',
            show: true,
            tickCount: data[0]?.data.length || 24,  // Adjust for the number of hours
            format: (hour) => `${hour * 1}:00`
        },
        { type: 'linear', position: 'left' }
    ];

    // Fetch data based on selected truck and date
    const fetchData = async () => {
        if (!truckTags || !truckDate) {
            message.error("Please select both truck and date.");
            return;
        }

        setLoading(true);

        try {
            const response = await axios.get(`${window.vtrack_config.REACT_APP_API_URL}/metrics/trips_per_hour`, {
                params: {
                    truck_tags: truckTags,
                    chosen_date: truckDate.format('YYYY-MM-DD')
                }
            });

            if (response.status === 200) {
                const resultData = response.data;
                let chartData = [];

                for (const [truckTag, metrics] of Object.entries(resultData)) {
                    const hours = Array.from({ length: 24 }, (_, i) => ({ hour: i, trip_count: 0 })); // Create an array of hours from 0 to 23
                    const dataMap = new Map(metrics.map(item => [item.hour, item])); // Map original data by hour

                    for (const hour of hours) {
                        if (dataMap.has(hour.hour)) {
                            hour.trip_count = dataMap.get(hour.hour).trip_count;
                            hour.unloadings = dataMap.get(hour.hour).unloadings;
                            hour.loadings = dataMap.get(hour.hour).loadings;
                            hour.yard_shifts = dataMap.get(hour.hour).yard_shifts;
                            hour.empty_trips = dataMap.get(hour.hour).empty_trips;
                        }
                    }
                    // Calculate total trips for this truck
                    const total = metrics.reduce((sum, item) => sum + item.trip_count, 0);
                    // Add the data for this truck to chartData
                    chartData.push({
                        truck_imei: truckTag,
                        //truck_name: 
                        data: hours.map(d => ({ hour: d.hour, trip_count: d.trip_count, unloadings: d.unloadings, loadings: d.loadings, yard_shifts: d.yard_shifts, empty_trips: d.empty_trips })),
                        total_count: total
                    });
                }
                console.log(chartData);
                setData(chartData);
            } else {
                message.error("Failed to load trips data.");
            }
        } catch (error) {
            console.log(error);
            message.error("An error occurred while fetching the data.");
        }

        setLoading(false);
    };

    function CustomTooltip(props) {
        if (props.active && props.payload[0] != null) {
            let newPayload = [
                ...props.payload,
                {
                    name: "Trips count",
                    value: props.payload[0].payload.trip_count
                },
            ];

            return <DefaultTooltipContent {...props} payload={newPayload} />
        } else {
            return <DefaultTooltipContent {...props} />;
        }
    }

    function formatHour(hourNumber) {
        hourNumber = hourNumber.toString();
        while (hourNumber.length < 2) hourNumber = "0" + hourNumber;
        return hourNumber;
    }

    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const value = payload.value;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`Hour ${formatHour(value)}:00`}
                    </text>
                </g>
            );
        }
    }

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>

            <Title level={2}>Trips per Day</Title>

            {/* Truck selection */}
            <div style={{ display: 'flex', columnGap: 32 }}>
                <div>
                    <Text>Select Truck:</Text>
                    <Select
                        style={{ width: 200, marginLeft: 10 }}
                        placeholder="Select truck"
                        mode="multiple"
                        allowClear
                        value={truckTags}
                        onChange={(value) => { setTruckTags(value); console.log(value) }}
                        options={props.dataZoomTrucksLookup}
                    >
                    </Select>
                </div>
                {/* Date selection */}
                <div >
                    <Text>Select Date:</Text>
                    <DatePicker
                        style={{ marginLeft: 10 }}
                        onChange={(date) => setTruckDate(date)}
                        placeholder="Select date"
                    />
                </div>
            </div>


            {/* Fetch data button */}
            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={fetchData} loading={loading}>
                    Fetch Data
                </Button>
            </div>

            {/* Charts */}
            <div style={{ marginTop: 20 }}>
                {data.map((truckData, index) => (
                    <div key={index} style={{ marginBottom: 40 }}>
                        <Title level={4}>{trucksMap[truckData.truck_imei]} ({truckData.truck_imei})</Title>
                        <Text>Total Trips: {truckData.total_count}</Text>  {/* Display total trips */}

                        <div style={{ height: '400px', marginTop: 16 }}>
                            {/* <Chart data={[truckData]} series={series} axes={axes} tooltip /> */}
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={200}
                                    data={truckData.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />

                                    <XAxis dataKey="hour"
                                        tick={<CustomizedXAxisTick />}
                                        height={80}

                                    />
                                    <YAxis
                                        label={{
                                            value: 'Number of Trips',
                                            angle: -90, // Rotate the label for vertical orientation
                                            position: 'insideLeft', // Position the label inside the Y-axis on the left
                                            offset: -10, // Adjust offset to move it closer to the axis
                                            style: { textAnchor: 'middle' } // Center-align the text
                                        }} />
                                    <Tooltip
                                        content={<CustomTooltip />}
                                        labelFormatter={value => {
                                            return `Hours ${formatHour(value)}:00 - ${formatHour(value+1)}:00`
                                        }} />
                                    <Bar dataKey="unloadings" name="Unloadings" stackId="a" fill={tripTypeColor[1]} />
                                    <Bar dataKey="loadings" name="Loadings" stackId="a" fill={tripTypeColor[2]} />
                                    <Bar dataKey="yard_shifts" name="Yard Shifts" stackId="a" fill={tripTypeColor[3]} />
                                    <Bar dataKey="empty_trips" name="Empty Trips" stackId="a" fill={tripTypeColor[0]} />
                                    <Legend />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
