import { useState, useRef, useEffect } from 'react';
import SVG from 'react-inlinesvg';

export default function Deck(props) {
    const [svgSource, setSvgSource] = useState(null);
    const [yCoord, setYCoord] = useState(0);
    const [svgWidth, setSvgWidth] = useState(null);
    const svgRef = useRef(null);
    const [testCoords, setTestCoords] = useState({x: 0, y: 0});

    const [deckSlots, setDeckSlots] = useState([]);

    useEffect(() => {
        if (props.source) {
            setSvgSource(props.source);
        }
    }, [props.source])
    useEffect(() => {
        setYCoord(props.yCoord);
    }, [props.yCoord])

    function handleLoaded() {
        props.handleLoaded();

        const { id } = props.config;
        const lanes = props.config.lanes;
        const styles = props.config.styles || {};
        const deckElement = svgRef.current.querySelector(`#${id}`);
        console.log(deckElement);
        let slots = [];
        setSvgWidth(deckElement.getAttribute("width"));
        //This is where slots are generated
        //Lane is divided into equal amount of slots that is given in config
        //Another solution might be that the input is an array of polygons
        //and coordinates in meters are translated to svg
        if (lanes) {
            for (const [lane_name, lane_config] of Object.entries(lanes)) {
                const lane = deckElement.querySelector(`#${lane_name}`);
                if(lane){
                    const count = lane_config.count;
                    let { width, height, x, y } = lane.getBBox();
                    width = width / count;
                    for (let i = 0; i < count; i++) {
                        slots.push(<rect className='trailer_slot' key={`${id}-${lane_name}-${i+1}`} transform={`translate(${x + (width * i) + 1} ${y + 1})`} width={width - 2} height={height - 2} />)
                    }
                    setDeckSlots(<g style={styles}>{slots}</g>);
                }
            }
        }

    }

    function translateToSVG(x, y){
        const y_scale_factor= 2.61;
        const x_scale_factor = 2.611404355;
        const origin_x = -24.891906312;
        const origin_y = 82.22805;

        //setTestCoords({x: origin_x + (x * x_scale_factor), y: origin_y - (y * y_scale_factor)})
    }

    useEffect(() => {
        translateToSVG(77.250, 23.856);
    }, [])

    const realX = 139 
    const realY = 6.158
    const testCoord = (realX - 9.531) * 2.6113
    const textCoordY = (73 - realY) * 2.6927 - 114.1; // 74.5

    return (
        <g transform={`translate(0,${yCoord})`} ref={svgRef}>
            <text fill='white' x={svgWidth} y={70} style={{pointerEvents: "none",   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none'}}>{props.config.name}</text>
            <SVG  src={svgSource} onLoad={handleLoaded} />
            {deckSlots}
            {/* <circle cx={testCoord} cy={textCoordY} fill='red' r={1} className='testPoint'/> */}
        </g>
    )
}